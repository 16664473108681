<template>
  <form>
    <loading-overlay
      :active="loadingOverlay"
      :is-full-page="true"
      loader="bars"
    />
    <CCard>
      <CCardHeader class="text-center dark text-white">
        <CRow>
          <CCol sm="11" class="d-flex ">{{title}}</CCol>
          <CCol sm="1" class="d-flex justify-content-end">
            <button type="button" aria-label="Close" class="close" @click="close(false)">×</button>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" lg="10">
            <CTextarea
              :label="$t('label.observation')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
              :placeholder="$t('label.Description')"
              v-uppercase
              :is-valid="hasError($v.Observation)"
              :invalid-feedback="errorMessage($v.Observation)"
              v-model.trim="$v.Observation.$model"
            />
          </CCol>
          <CCol sm="12" lg="2" class="d-flex justify-content-end">
            <CRow>
              <CCol sm="12" lg="auto">
                <CButton
                  square
                  color="add"
                  class="mr-2"
                  size="sm"
                  @click="DeleteTaraweight()"
                  :disabled="isSubmit"
                >
                  <CIcon name="checkAlt" />
                </CButton>
                <CButton
                  color="wipe"
                  size="sm"
                  v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                  @click="Reset()"
                >
                  <CIcon name="cil-brush-alt" />
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </form>  
</template>
<script>
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import { required, maxLength } from 'vuelidate/lib/validators';
  
  function data() {
    return {
      StowagePlanningLogWeigthId: '',
      Observation: '',
      loadingOverlay: false,
      isSubmit: false,
    };
  }

  function DeleteTaraweight(){
    this.isSubmit = true;
    this.$swal.fire({
      text: `${this.$t('label.deleteQuestion')} ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }else{
          this.isSubmit = false;
        }
    });
  }

  function submit() {
    try{
        this.loadingOverlay = true;
        this.$v.$touch();
        if (this.$v.$error) {
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsPleaseCheck');
        }
        let LogisticJson = {
            StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
            Observation: this.Observation,
        }
        this.$http
            .put('StowagePlanningLogisticWeigthOrder-Deactivate', LogisticJson, { root: 'LogisticJson' })
            .then((response) => {
                if (response && response.status === (200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.loadingOverlay = false;
                    this.isSubmit = false;
                    this.close(true);
                    this.$notify({
                        group: 'container',
                        title: '¡'+this.$t('label.success')+'!',
                        text: messageSuccess,
                        type: "success"
                    });
                    } 
                }).catch((e) => {
                    this.loadingOverlay = false;
                    this.isSubmit = false;
                    this.$notify({
                        group: "container",
                        title: "¡Error!",
                        text: e,
                        type: "error",
                    });
                });
    }catch (e) {
        this.isSubmit = false;
        this.loadingOverlay = false;
        this.$notify({
            group: "container",
            title: "¡Error!",
            text: e,
            type: "error",
        });
    }
    
  }
  
  function close(refresh) {
    this.$emit('set-Weighing-list', refresh);
  }

  function Reset() {
    this.Observation = '';
    this.StowagePlanningLogWeigthId = '';
  }
  
  function title(){
    if(this.isFull){
      return this.$t('label.delete')+' '+this.$t('label.FullWeight')+': '+this.Item.OrderCode;
    }else{
      let title = this.Item.OrderCode ? this.Item.OrderCode : this.Item.ImplementAlias;
      return this.$t('label.delete')+' '+this.$t('label.Taraweight')+': '+title;
    }
  }
  
  export default {
    name: 'collapse-detele-weight',
    props: { collapse: Boolean, Item: Object, isFull: Boolean },
    data,
    mixins: [
      GeneralMixin, 
    ],
    validations: {
      StowagePlanningLogWeigthId: { required },
      Observation: { required, maxLength: maxLength(250) }
    },
    directives: UpperCase,
    methods: {
      DeleteTaraweight,
      submit,
      close,
      Reset,
    },
    computed: {
        title,
    },
    watch: {
      collapse: async function(val){
        if (val) {
          this.StowagePlanningLogWeigthId = this.Item.StowagePlanningLogWeigthId;
          this.$v.$reset();
        }else{
            this.Reset();
        }
      },
    },
    
  };
  </script>